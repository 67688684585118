.site-header {
  box-shadow: 0px 5px 10px 0px #888;
  .me-block.me-Image{
    max-width: 450px;
    width: 100%;
  }
}

.home-news {
  .slider-arrow{
    background-color: #B2C6E2;
    height: 60px;
    text-align: center;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 1024px){
      display:none;
    }
    i{
      width: 0;
      height: 0;
    }
    i:before{content:"";}
    @media screen and (max-width: 640px){
      display:none;
    }
  }
  .slider-arrow {
    display: none !important;
  }
  @media #{$large-up} {
    display: block !important;
    .slider-arrow.slider-arrow-left{
      left: -60px !important;
      i{
        border-bottom: 15px solid rgba(0, 0, 0, 0);
        border-right: 20px solid #FFF;
        border-top: 15px solid rgba(0, 0, 0, 0);
      }
    }
    .slider-arrow.slider-arrow-right{
      right: -60px !important;
      i{
        border-bottom: 15px solid rgba(0, 0, 0, 0);
        border-left: 20px solid #FFF;
        border-top: 15px solid rgba(0, 0, 0, 0);
      }
    }
  }

}

.home-banner, .layer-overlay {
  h1, p {
    line-height: 1;
  }
  p {padding-bottom: 0;}
}

.me-ProdAutoFinder,
.LocatorResultList {
  ul, li {
    list-style: none;
  }
}

.me-LocatorSearch {
  & > .row {
    margin-bottom: 20px;
    input {margin: 0 !important;}
    button {
      flex:1;
    }
  }
}

.me-CartChangePass {
  @include placeholder(black);
  input:not([type="submit"]), textarea{
    background-color: rgba(63, 113, 183, 0.1);
    border:1px solid #3f71b7;
    border-radius: 3px;
  }
  textarea{min-height: 150px;}
  input[type="submit"] {
    width:100%;
    max-width: 300px;
  }
}

.hide-for-frontend {
  display: none;
}

.me-CartLogin, .me-CartResetPass {
  @include placeholder(white);
  input:not([type="submit"]),textarea{
    background-color: rgba(255,255,255,0.2);
    border:0;
    box-shadow:none;
    color:white;
  }
  input[type="submit"], button[type="submit"] {
   width: auto;
    margin: 0 auto;
  }
}


.me-Gallery {
  .hero {
    position: relative;
    .caption {
      position: absolute;
      bottom: 0;
      background-color: rgba(0,0,0,0.3);
      color: white;
      padding: 10px;
      width: 100%;
    }
  }

  .column.item.masonry {
    position: relative;
    img {
      background-color: rgba(0,0,0,0.1);
      padding: 5px;
    }
    .caption{
      background: rgba(0,0,0,0.8);
      border-radius: 3px;
      bottom: -10px;
      color:white;
      position: absolute;
      left: 50%;
      opacity:0;
      padding: 10px 0;
      text-align: center;
      -webkit-transform: translate(-50%, 100%);
      -moz-transform: translate(-50%, 100%);
      -ms-transform: translate(-50%, 100%);
      -o-transform: translate(-50%, 100%);
      transform: translate(-50%, 100%);
      visibility:hidden;
      width: 250px;
      &:before,&:after{
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid rgba(0,0,0,0.3);
        content: "";
        left: 50%;
        top: -10px;
        position: absolute;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        transform: translateX(-50%);
        z-index: 1;
      }
    }
    &:hover{
      .caption{
        opacity: 1;
        visibility: visible;
        z-index: 99;
      }
    }
  }
}

@media screen and (max-width: 979px) {
  table.responsive {
    border: 0;
  }
  table.responsive thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table.responsive tr {
    border-bottom: 3px solid #ddd;
    display: block;
  }

  table.responsive td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    text-align: right;
  }

  table.responsive td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  table.responsive td:last-child {
    border-bottom: 0;
  }
}


//.me-doc.me-owrap{
//    .mobile-hide{
//      @media screen and (max-width: 640px){
//        display:none;
//      }
//    }
//    .site-header{
//      & > .me-block{
//        box-shadow: 0px 5px 10px 0px #888;
//        background: #F8F8F8;
//        //position: relative;
//        z-index: 3;
//      }
//      .me-block.me-Image{
//        padding: 0 15px;
//        max-width: 450px;
//        width: 100%;
//      }
//    }
//
//    .me-block.me-Menu{
//      @media screen and (max-width: 980px){
//        display:none;
//      }
//    }
//
//    .responsive-menu-trigger{
//      cursor: pointer;
//      display:none;
//      position: absolute;
//      right: 20px;
//      top: 50%;
//      width: 30px;
//      z-index: 3;
//      -webkit-transform: translateY(-50%);
//      -moz-transform: translateY(-50%);
//      -ms-transform: translateY(-50%);
//      -o-transform: translateY(-50%);
//      transform: translateY(-50%);
//      .top, .mid, .bottom{
//        background-color: #a5a5a5;
//        display: block;
//        height: 5px;
//        margin-bottom: 5px;
//        position: relative;
//        width: 100%;
//        -webkit-transition: all 0.2s;
//        -moz-transition: all 0.2s;
//        -ms-transition: all 0.2s;
//        -o-transition: all 0.2s;
//        transition: all 0.2s;
//      }
//      @media screen and (max-width: 980px){
//        display:block;
//      }
//      &.active{
//        .top{
//          top: 9px;
//          -webkit-transform: rotate3d(0,0,1,45deg);
//          -moz-transform: rotate3d(0,0,1,45deg);
//          -ms-transform: rotate3d(0,0,1,45deg);
//          -o-transform: rotate3d(0,0,1,45deg);
//          transform: rotate3d(0,0,1,45deg);
//        }
//        .mid{opacity: 0;}
//        .bottom{
//          top:-10px;
//          -webkit-transform: rotate3d(0,0,1,-45deg);
//          -moz-transform: rotate3d(0,0,1,-45deg);
//          -ms-transform: rotate3d(0,0,1,-45deg);
//          -o-transform: rotate3d(0,0,1,-45deg);
//          transform: rotate3d(0,0,1,-45deg);
//        }
//      }
//    }
//
//    .responsive-menu{
//      margin:0;
//      max-height: 0;
//      overflow:hidden;
//      -webkit-transition: all 0.2s ease;
//      -moz-transition: all 0.2s ease;
//      -ms-transition: all 0.2s ease;
//      -o-transition: all 0.2s ease;
//      transition: all 0.2s ease;
//      ul{margin:0;}
//      li{
//        width: 100%;
//        a{
//          background-color: #333333;
//          border-bottom: 1px dotted white;
//          color:white !important;
//          display:block;
//          font-size: 20px;
//          padding: 10px 0;
//          text-align: center;
//        }
//        ul{
//          li a{
//            font-size: 16px;
//            color: #FA8621 !important;
//            ul li a{
//              font-size: 14px;
//            }
//          }
//        }
//      }
//      &.show{
//        max-height: 1000px;
//        position: relative;
//        z-index: 99;
//        @media screen and (min-width: 981px) {
//          display:none;
//        }
//      }
//    }
//    .slick-slider{
//      margin-bottom: 60px;
//      @media screen and (max-width: 640px){
//        margin-bottom: 0;
//      }
//    }
//    .home-banner{
//      .me-block.me-Slider{
//        overflow:hidden;
//        @media screen and (max-width: 1920px){
//          max-height: 558px;
//        }
//        @media screen and (max-width: 1440px){
//          max-height: 430px;
//        }
//        @media screen and (max-width: 1280px){
//          max-height: 390px;
//        }
//        //@media screen and (max-width: 360px){
//        //  max-height: 186px;
//        //}
//        //@media screen and (max-width: 320px){
//        //  max-height: 165px;
//        //}
//      }
//      .slider-arrow{
//        display: none;
//      }
//      .slick-dots {
//        bottom: -55px;
//        font-size: 0;
//        margin: 0;
//        li {
//          button {
//            width: 16px;
//            height: 16px;
//            background: #ABACA8;
//            border-radius: 15px;
//            padding: 0;
//            margin: 0;
//            opacity: 0.5;
//            &:before {
//              content: "";
//              left: 50%;
//              top: 50%;
//              -webkit-transform: translate(-50%, -50%);
//              -moz-transform: translate(-50%, -50%);
//              -ms-transform: translate(-50%, -50%);
//              -o-transform: translate(-50%, -50%);
//              transform: translate(-50%, -50%);
//              opacity: 1;
//              background-color: #DDDDDD;
//              width: 8px;
//              height: 8px;
//              -webkit-border-radius: 13px;
//              -moz-border-radius: 13px;
//              border-radius: 13px;
//            }
//          }
//          &.slick-active {
//            button {
//              background-color: #E6E6E5;
//              &:before {
//                background-color: #3f71b7;
//              }
//              opacity: 1;
//            }
//          }
//          button:hover{
//            background-color: #E6E6E5;
//            &:before {
//              background-color: #3f71b7;
//            }
//            opacity: 1;
//          }
//        }
//      }
//      @media screen and (max-width: 640px){
//        img{
//          width: 196% !important;
//          max-width: 200%;
//          position: absolute;
//          left:50%;
//          -webkit-transform: translateX(-50%);
//          -moz-transform: translateX(-50%);
//          -ms-transform: translateX(-50%);
//          -o-transform: translateX(-50%);
//          transform: translateX(-50%);
//        }
//      }
//      .item.slick-slide{
//        @media screen and (max-width:640px){
//          height: 200px;
//        }
//        position: relative;
//        max-width: 100%;
//        overflow:hidden;
//        & > .me-owrap.me-Columns2{
//          bottom: 0;
//          position: absolute;
//          width: 100%;
//          .me-owrap.me-Columns2{
//            bottom: 55px;
//            left: 0;
//            position: absolute;
//            &.label-right{right:0;left:auto;}
//            @media screen and (max-width: 980px) {
//              bottom: 0;
//            }
//            @media screen and (max-width: 640px) {
//              display:none;
//            }
//            .me-block.me-Heading{
//              h1,h2,h3,h4,h5,h6{line-height:1;}
//              h1,h2,h3{
//                font-size: 2.5vw !important;
//                @media screen and (max-width: 980px){
//                  font-size: 4vw !important;
//                }
//              }
//            }
//            .me-block.me-CKEditor {
//              //font-size: 28px;
//              font-size: 2vw;
//              @media screen and (max-width: 980px) {
//                font-size: 3vw;
//              }
//            }
//          }
//        }
//      }
//    }
//
//    .banner-text{
//      bottom: 0;
//      position: absolute;
//      width: 100%;
//      .me-owrap.me-Columns2{
//        bottom: 22px;
//        left: 0;
//        position: absolute;
//        .me-block.me-Heading{
//          h1,h2,h3,h4,h5,h6{line-height:1;}
//        }
//        .me-block.me-CKEditor {
//          font-size: 28px;
//        }
//      }
//      @media screen and (max-width: 640px) {
//        display: none;
//      }
//    }
//
//    .banner-strip{
//      height: 300px;
//      overflow: hidden;
//      position: relative;
//      img{
//        position: absolute;
//        top: 50%;
//        width: 100%;
//        -webkit-transform: translateY(-50%);
//        -moz-transform: translateY(-50%);
//        -ms-transform: translateY(-50%);
//        -o-transform: translateY(-50%);
//        transform: translateY(-50%);
//        @media screen and (max-width: 980px){
//          position: relative;
//          top:auto;
//          -webkit-transform: translateY(0);
//          -moz-transform: translateY(0);
//          -ms-transform: translateY(0);
//          -o-transform: translateY(0);
//          transform: translateY(0);
//        }
//      }
//      @media screen and (max-width: 980px) {
//        height:auto;
//      }
//      @media screen and (max-width: 640px) {
//        .me-block.me-Hamle{
//          height: 190px;
//          position: relative;
//          img{
//            left:50%;
//            max-width: 200%;
//            position: absolute;
//            -webkit-transform: translateX(-50%);
//            -moz-transform: translateX(-50%);
//            -ms-transform: translateX(-50%);
//            -o-transform: translateX(-50%);
//            transform: translateX(-50%);
//            width: 200%;
//          }
//        }
//      }
//    }
//
//    .map-block > .me-block {
//      & > .overlay {
//        background-size: 84%;
//        background-position: center;
//        @media screen and (max-width: 640px){
//          background-size: contain;
//        }
//      }
//      & > .me-iwrap{
//        .me-owrap.me-CKEditor{
//          line-height: 1;
//          padding: 0 30px;
//          position: absolute;
//          top:50%;
//          -webkit-transform: translateY(-50%);
//          -moz-transform: translateY(-50%);
//          -ms-transform: translateY(-50%);
//          -o-transform: translateY(-50%);
//          transform: translateY(-50%);
//          @media screen and (max-width: 980px){
//            padding: 20px 40px;
//          }
//          @media screen and (max-width: 640px){
//            line-height: 1.5;
//            padding:20px 10px;
//            position: relative;
//            left: 0;
//            -webkit-transform: translateY(0);
//            -moz-transform: translateY(0);
//            -ms-transform: translateY(0);
//            -o-transform: translateY(0);
//            transform: translateY(0);;
//          }
//        }
//        .me-owrap.me-Button2{
//          bottom: 0;
//          left: 50%;
//          max-width: 100%;
//          position: absolute;
//          width: 100%;
//          -webkit-transform: translateX(-50%);
//          -moz-transform: translateX(-50%);
//          -ms-transform: translateX(-50%);
//          -o-transform: translateX(-50%);
//          transform: translateX(-50%);
//          @media screen and (max-width: 640px){
//            position: relative;
//          }
//        }
//      }
//    }
//    .quote-block > .me-block{
//      & > .me-iwrap{
//        .me-owrap.me-Columns2{
//          position: absolute;
//          top: 50%;
//          -webkit-transform: translateY(-50%);
//            -moz-transform: translateY(-50%);
//            -ms-transform: translateY(-50%);
//            -o-transform: translateY(-50%);
//            transform: translateY(-50%);
//            width: 100%;
//          .me-owrap.me-CKEditor{
//            margin:40px auto 60px;
//          }
//          @media screen and (max-width: 640px) {
//            position: relative;
//            top: auto;
//            -webkit-transform: translate(0);
//            -moz-transform: translate(0);
//            -ms-transform: translate(0);
//            -o-transform: translate(0);
//            transform: translate(0);
//          }
//        }
//      }
//    }
//
//    .home-blocks{
//      padding-bottom: 0 !important;
//      .item{
//        padding-bottom: 20px;
//      }
//    }
//
//    .me-block.me-Form{
//      background-color: #fa8621;
//    }
//  }
//  .cboxIframe{
//    background-color:transparent;
//  }




  table.vehicle-list{
    width:100%;
    tbody {
      &:nth-child(2n+1) {
        background-color: #F9F9F9;
      }
      tr {
        background: inherit;
      }
    }
    strong { color: #444; }
  }

  table.specifications{
    tr:nth-child(odd){
      background-color: #F9F9F9;
    }
    tr:nth-child(even){
      background-color: #FFFFFF;
    }
  }
  //Auto Product finder and search
  .me-block.me-ProdAutoCodeSearch{
    @include placeholder(white);
    input{
      background: rgba(255, 255, 255, 0.2);
      border-radius: 5px;
      color: #FFF;
      margin: 0;
    }
  }
  .searchcode{
    .me-block.me-Panel2{
      border:1px solid white;
      border-radius: 5px;
    }
  }

  //jquery ui for autocomplete
  .ui-autocomplete.ui-front.ui-menu.ui-widget.ui-widget-content{
    background-color: rgba(0,0,0,0.5);
    &:hover{
      background-color: rgba(0,0,0,0.5);
    }
    li{
      background-color: rgba(0,0,0,0.5);
      background-image: none;
      color:white;
      font-weight: normal !important;
      &:hover{
        background-color: rgba(0,0,0,0.5);
        background-image: none;
        border:1px solid #f2f2f2 !important;
        color:white;
      }
    }
    .ui-state-focus{border:1px solid #f2f2f2 !important;}
  }



  .me-block.me-ProdAutoFinder{
    select{
      background:rgba(255,255,255,0.2);
      border-radius: 5px;
      color:white;
      option, optgroup {
        background-color: rgba(0,0,0,0.5);
      }
    }
    ul{
      margin: 0;
      li.item{
        background:white;
        border-radius: 5px;
        margin:0 0 1rem;
        padding: 10px;
        h6 {
          color: #FA8621 !important;
          font-family:OpenSansRegular, sans-serif !important;
          font-size: 15px !important;
          @media screen and (max-width: 640px){
            text-align: center;
          }
        }
        .me-owrap.me-Hamle{
          h6{
            background-color: #EEEEEE;
            color: #233753 !important;
            display:block;
            margin: 0 10px;
            font-size: 13px !important;
            padding: 10px;
            text-align: left !important;
          }
        }
      }
    }
  }
  #prodautofinder_matches {
    optgroup, option {
      background: white;
      color: #555;
    }
    option {
      color: #fa8621;
    }
  }

    .filelist{
    a{
      display:block;
      width: 100%;
      background: #FA8621;
      border: 0;
      color: #FFF !important;
      margin: 5px auto;
      padding: 10px;
      font-size: 18px;
    }
  }

  //.on-show-event{
  //  .item{
  //    padding-bottom: 0;
  //    img{
  //      background: rgba(0,0,0, 0.1);
  //      padding: 5px;
  //    }
  //  }
  //  a{
  //    cursor: pointer;
  //    display:block;
  //    position: relative;
  //    .caption{
  //      background: rgba(0,0,0,0.8);
  //      border-radius: 3px;
  //      bottom: -10px;
  //      color:white;
  //      position: absolute;
  //      left: 50%;
  //      opacity:0;
  //      padding: 10px 0;
  //      text-align: center;
  //      -webkit-transform: translate(-50%, 100%);
  //      -moz-transform: translate(-50%, 100%);
  //      -ms-transform: translate(-50%, 100%);
  //      -o-transform: translate(-50%, 100%);
  //      transform: translate(-50%, 100%);
  //      visibility:hidden;
  //      width: 250px;
  //      &:before,&:after{
  //        border-left: 10px solid transparent;
  //        border-right: 10px solid transparent;
  //        border-bottom: 10px solid rgba(0,0,0,0.3);
  //        content: "";
  //        left: 50%;
  //        top: -10px;
  //        position: absolute;
  //        -webkit-transform: translateX(-50%);
  //        -moz-transform: translateX(-50%);
  //        -ms-transform: translateX(-50%);
  //        -o-transform: translateX(-50%);
  //        transform: translateX(-50%);
  //        z-index: 1;
  //      }
  //    }
  //    &:hover{
  //      .caption{
  //        opacity: 1;
  //        visibility: visible;
  //        z-index: 99;
  //      }
  //    }
  //  }
  //}

  .filter-tab{
    ul{margin:0;}
    li{
      background-color: #3F71B7;
      border-radius: 3px;
      color:white !important;
      cursor: pointer;
      display:inline-block;
      margin: 5px;
      padding: 5px 10px;
    }
    @media screen and (max-width: 480px){
      ul{
        text-align: center;
        li{
          display: block;
          margin: 5px auto;
          max-width: 300px;
        }
      }
    }
  }


  .model-title{
    background-color: #3F71B7;
    h2, h3{color: white !important;}
    @media screen and (max-width: 640px){
      text-align: center;
    }
  }
  .model-filter ul{
    margin:0;
  }

  .tank-statement{
    padding: 20px !important;
  }

  .prodauto {
    background-color: #FA8621;
    margin: 0 0 15px;
    padding: 10px;
    text-align: center;
    h5{color: white !important;}
  }

  .price-block{
    background-color: #F9f9f9;
    border: 1px solid #DDDDDD;
    padding: 10px 0 0;
    h5{width:100%;}
    @media screen and (max-width: 640px){
      h4,h5,h6{font-size: 14px !important;}
    }
  }

  //Find A Distributor -- Locator
  .me-block.me-LocatorSearch{
    h3{
      font-size: 18px !important;
      color:#FA8621 !important;
    }
    @include placeholder(white);
    .LocatorSearchName,
    .LocatorSearchWhere{
      background-color: rgba(255, 255, 255, 0.2);
      color: white;
    }
  }
  button.LocatorSearchFind.postfix,
  button.LocatorSearchLocate.postfix{
    background: #3f71b7;
    border: 0;
    margin: 0;
  }
  .panel-wrap{
    color:#555555;
    a{
      color: #3f71b7 !important;
    }
  }
  .LocatorResultList{
    ul{margin:0;}
    li{
      padding: 20px;
      &:nth-child(odd){background-color: #F2F2F2;}
      &:nth-child(even){background-color: white;}
      .row[data-equal] .columns{
        -ms-flex-align: center;
        -webkit-align-items: center;
        -webkit-box-align: center;
        align-items: center;
        -webkit-justify-content: flex-start;
        justify-content: flex-start;
      }
    }
  }


  a.login, .header-logout{
    @media #{$small-only} {
      display:none;
    }
  }
  a.login {
    color: #999 !important;
    font-size: 12px;
    text-transform: uppercase;
    padding-right: 10px;
  }

  .header-logout{
    ul{
      margin:0;
      font-size: 0 !important;
      text-align:right;
      li{
        display:inline-block;
        vertical-align:middle;
        list-style: none;
        a{
          font-size: 12px;
          text-transform: uppercase;
          color: #999 !important;
          padding: 0 10px;
        }
        &:first-child a {border-right: 1px solid #999;}
      }
    }
  }

  .changepass-ok.alert-box.info{
    background-color: #FA8621;
    border: 0;
    color: #FFF;
    border-radius: 3px;
  }

  .uglystart{
    height:0;
    overflow:hidden;
  }

  .noimage{
    display:none;
  }

  .fb_iframe_widget span{vertical-align: top !important;}

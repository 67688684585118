body { background-color: $COL14; }
body { color: $COL15; }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
color: $COL16;
font-family: 'Open Sans';
font-weight: 700;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 25px;

@media #{$medium-up} {
font-size: 35px;

}
@media #{$large-up} {
font-size: 40px;

}
}
h2 {
color: $COL17;
font-family: 'Open Sans';
font-weight: 700;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 22px;

@media #{$medium-up} {
font-size: 30px;

}
@media #{$large-up} {
font-size: 35px;

}
}
h3 {
color: $COL18;
font-family: 'Open Sans';
font-weight: 700;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 28px;

}
@media #{$large-up} {
font-size: 30px;

}
}
h4 {
color: $COL19;
font-family: 'Open Sans';
font-weight: 700;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 18px;

@media #{$medium-up} {
font-size: 25px;

}
}
h5 {
color: $COL20;
font-family: 'Open Sans';
font-weight: 700;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 18px;

@media #{$medium-up} {
font-size: 20px;

}
}
h6 {
color: $COL21;
font-family: 'Open Sans';
font-weight: 700;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
.button {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-body {
font-family: 'Arapey';
font-weight: normal;
font-style: italic;
line-height: 1.5;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 35px;

}
}
.me-Quote .quote-author {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul {
color: $COL22;
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
summary {
font-family: 'Open Sans';
font-weight: 700;
font-style: normal;
line-height: 1;
letter-spacing: 0;
font-size: 18px;

@media #{$medium-up} {
font-size: 25px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 20px;

}
body {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

}
p { padding-bottom: 1em; }
a {color: $COL23}
a:hover {color: $COL24}
/* Default:2 */
.MES2 {
background-color: $COL2;
color: $COL9;
padding: 10px;

 }
.MES2 {
background-color: $COL2;
color: $COL9;
padding: 10px;

h1.MEC2, h2.MEC2, h3.MEC2, h4.MEC2, h5.MEC2, h6.MEC2 { color: $COL1;
 }
h1.MEC2 { font-size: 10px; }
h1.MEC2 { @media #{$medium-up} { font-size: 21px; }; }
h1.MEC2 { @media #{$large-up} { font-size: 40px; }; }
h2.MEC2 { font-size: 8.8px; }
h2.MEC2 { @media #{$medium-up} { font-size: 18px; }; }
h2.MEC2 { @media #{$large-up} { font-size: 35px; }; }
h3.MEC2 { font-size: 8px; }
h3.MEC2 { @media #{$medium-up} { font-size: 16.8px; }; }
h3.MEC2 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC2 { font-size: 7.2px; }
h4.MEC2 { @media #{$medium-up} { font-size: 15px; }; }
h4.MEC2 { @media #{$large-up} { font-size: 25px; }; }
h5.MEC2 { font-size: 7.2px; }
h5.MEC2 { @media #{$medium-up} { font-size: 12px; }; }
h5.MEC2 { @media #{$large-up} { font-size: 20px; }; }
h6.MEC2 { font-size: 6.4px; }
h6.MEC2 { @media #{$medium-up} { font-size: 10.8px; }; }
h6.MEC2 { @media #{$large-up} { font-size: 18px; }; }
 }
cite.MEC2{
color: $COL9;
}
/* Primary:30 */
.MES30 {
background-color: $COL25;
color: $COL26;
 }
.MES30 {
background-color: $COL25;
color: $COL26;
h1.MEC30, h2.MEC30, h3.MEC30, h4.MEC30, h5.MEC30, h6.MEC30 { color: $COL26;
 }
 }
cite.MEC30{
color: $COL26;
}
/* Secondary:31 */
.MES31 {
background-color: $COL27;
color: $COL28;
 }
/* Secondary:32 */
.MES32 {
background-color: $COL27;
color: $COL28;
 }
.MES32 {
background-color: $COL27;
color: $COL28;
h1.MEC32, h2.MEC32, h3.MEC32, h4.MEC32, h5.MEC32, h6.MEC32 { color: $COL28;
 }
 }
a.MEC32 { color: $COL14;
&:hover { color: $COL14; }
 }
cite.MEC32{
color: $COL28;
}
/* Alternate:33 */
.MES33 {
background-color: $COL29;
color: $COL30;
 }
/* Alternate:34 */
.MES34 {
background-color: $COL29;
color: $COL30;
 }
.MES34 {
background-color: $COL29;
color: $COL30;
h1.MEC34, h2.MEC34, h3.MEC34, h4.MEC34, h5.MEC34, h6.MEC34 { color: $COL30;
 }
 }
cite.MEC34{
color: $COL30;
}
/* Light:35 */
.MES35 {
background-color: $COL31;
color: $COL32;
 }
/* Light:36 */
.MES36 {
background-color: $COL31;
color: $COL32;
 }
.MES36 {
background-color: $COL31;
color: $COL32;
h1.MEC36, h2.MEC36, h3.MEC36, h4.MEC36, h5.MEC36, h6.MEC36 { color: $COL32;
 }
 }
cite.MEC36{
color: $COL32;
}
/* Header Panel:37 */
.MES37 {
background-color: $COL34;
 }
.MES37 {
background-color: $COL34;
 }
/* Main Menu:38 */
nav.me-Menu.MES38 {
& .menu-item.MEC38, & .menu-item.MEC38 > div.MEC38{ & > a.MEC38{color: $COL25;
text-transform: uppercase;
}
 &:hover > a.MEC38{color: $COL9;
}
 }
&.horizontal > .menu-item.MEC38 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC38 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC38 .menu-item { border:0;
border-bottom-width: 1px;
border-color: $COL14;
border-style: solid;
 }
&.horizontal > .menu-item.MEC38 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 20px 10px;}

& .sub-menu .menu-item a{padding: 10px;}



&.horizontal .menu-item.MEC38:hover {& > .pointer-wrap > .pointer{border-bottom: 10px solid $COL35;}}
&.vertical .menu-item.MEC38:hover {& > .pointer-wrap > .pointer{border-right: 10px solid $COL35;
}}

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {
.menu-item.MEC38{background-color: $COL35;
 &:hover {background-color: $COL36}
}
& .menu-item.MEC38, & .menu-item.MEC38 > div.MEC38{ & > a.MEC38{color: $COL14;
text-transform: uppercase;
}
 &:hover > a.MEC38{color: $COL2;
}
 }

}
}
 }
/* Slider Mask Panel:40 */
.MES40 {
padding: 30px 0;

 }
.MES40 {
padding: 30px 0;

 }
/* Footer Panel:39 */
.MES39 {
background-color: $COL25;
color: $COL2;
padding: 15px;

@media #{$medium-up} {
padding: 30px 15px;

}
@media #{$large-up} {
padding: 40px 15px;

}
border-width: 8px 0 0 0;
border-style: solid;
border-color: $COL31 transparent transparent transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES39 {
background-color: $COL25;
color: $COL2;
padding: 15px;

@media #{$medium-up} {
padding: 30px 15px;

}
@media #{$large-up} {
padding: 40px 15px;

}
border-width: 8px 0 0 0;
border-style: solid;
border-color: $COL31 transparent transparent transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
h1.MEC39, h2.MEC39, h3.MEC39, h4.MEC39, h5.MEC39, h6.MEC39 { color: $COL14;
 }
 }
a.MEC39 { color: $COL14;
&:hover { color: $COL14; }
 }
cite.MEC39{
color: $COL2;
}
/* FAQs:41 */
details.MES41 {
color: $COL9;
& > article { color: $COL9;
 }
& > summary{padding: 18px 10px;}

& > article{padding: 10px;}

& > summary{border-width: 0 0 1px 0;
border-style:dotted;
border-color: transparent transparent $COL25 transparent;
}& > summary {& > div  > i{color: $COL25;
font-size: 18px;
@media #{$medium-up} {
font-size: 18px;
};
@media #{$large-up} {
font-size: 18px;
};
}}
& > summary { color: $COL9;
font-size:18px;
 }
 }
/* News Menu:42 */
.MES42 {
font-size: 16px;
@media #{$large-up} {
font-size: 12.8px;
};
padding: 10px 0;

border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent $COL14 transparent;
border-top-style: dotted;
border-right-style: dotted;
border-bottom-style: dotted;
border-left-style: dotted;
 }
.MES42 {
font-size: 16px;
@media #{$large-up} {
font-size: 12.8px;
};
padding: 10px 0;

border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent $COL14 transparent;
border-top-style: dotted;
border-right-style: dotted;
border-bottom-style: dotted;
border-left-style: dotted;
 }
cite.MEC42{
font-size: 16px;
@media #{$large-up} {
font-size: 12.8px;
};
}
/* Contact Form Button:43 */
.MES43 {
background-color: $COL25;
&:hover { background-color: $COL25;}
color: $COL14;
&:hover { color: $COL14;}
border-radius: 10px;
padding: 10px 50px;

 }
/* Contact Form:44 */
.me-block.me-Form.MES44,  body.MES44 {background-color:transparent;
  
 @include placeholder($COL9);
input:not([type='submit']), textarea, select {border-style: solid;
border-width: 1px;
border-radius: 3px 3px 3px 3px;
border-color: $COL25;
background-color: $COL37;
color: $COL9;
}
::-webkit-input-placeholder { color: $COL9; opacity: 0.5;}
:-moz-placeholder {color: $COL9; opacity: 0.5;} 
::-moz-placeholder {color: $COL9; opacity: 0.5;}
:-ms-input-placeholder {color: $COL9; opacity: 0.5;}

  }
/* Testimonial Panel:45 */
.MES45 {
font-size: 16px;
@media #{$large-up} {
font-size: 19.2px;
};
 }
.MES45 {
font-size: 16px;
@media #{$large-up} {
font-size: 19.2px;
};
 }
cite.MEC45{
font-size: 16px;
@media #{$large-up} {
font-size: 19.2px;
};
}
/* Popup Form :46 */
.me-block.me-Form.MES46,  body.MES46 {background-color:transparent;
 color: $COL2;
 
 @include placeholder($COL14);
input:not([type='submit']), textarea, select {border-style: solid;
border-width: 0px;
background-color: $COL38;
color: $COL14;
}
::-webkit-input-placeholder { color: $COL14; opacity: 0.5;}
:-moz-placeholder {color: $COL14; opacity: 0.5;} 
::-moz-placeholder {color: $COL14; opacity: 0.5;}
:-ms-input-placeholder {color: $COL14; opacity: 0.5;}

 .me-wrap-text a {color: $COL14;
&:hover{ color: $COL14; }
} }
/* Responsive Menu:47 */
nav.responsive-menu {
.menu-item.MEC47{background-color: $COL9;
}
& .menu-item.MEC47, & .menu-item.MEC47 > div.menu-item-wrap{ & > a.MEC47, & > i{color: $COL2;
text-align: left;
}
  }
& .menu-item.MEC47 { border:0;
border-color: $COL31;
border-style: dotted;

 border-bottom-width: 1px; }
& .menu-item > .menu-item-wrap {padding: 5px 15px;}

& .sub-menu{.menu-item.MEC47{background-color: $COL9;
}
& .menu-item.MEC47, & .menu-item.MEC47 > div.menu-item-wrap{ & > a.MEC47, & > i{color: $COL29;
text-align: left;
}
  }
 .sub-menu {}}

 }
/* Slider Pad:48 */
.MES48 {
padding: 15px;

@media #{$large-up} {
padding: 30px;

}
 }
.MES48 {
padding: 15px;

@media #{$large-up} {
padding: 30px;

}
 }
/* Vehicle List:49 */
details.MES49 {
& > summary{background-color: $COL25;
}
& > summary{padding: 15px;}

& > summary { color: $COL14;
 }
 }
/* Primary:29 */
.MES29 {
background-color: $COL25;
color: $COL26;
 }
/* Change Pass Panel:26 */
.MES26 {
background-color: $COL7;
color: $COL9;
padding: 20px;

 }
.MES26 {
background-color: $COL7;
color: $COL9;
padding: 20px;

h1.MEC26, h2.MEC26, h3.MEC26, h4.MEC26, h5.MEC26, h6.MEC26 { color: $COL3;
 }
h1.MEC26 { font-size: 22.5px; }
h1.MEC26 { @media #{$large-up} { font-size: 40px; }; }
h2.MEC26 { font-size: 19.8px; }
h2.MEC26 { @media #{$large-up} { font-size: 35px; }; }
h3.MEC26 { font-size: 18px; }
h3.MEC26 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC26 { font-size: 16.2px; }
h4.MEC26 { @media #{$large-up} { font-size: 25px; }; }
h5.MEC26 { font-size: 16.2px; }
h5.MEC26 { @media #{$large-up} { font-size: 20px; }; }
h6.MEC26 { font-size: 14.4px; }
h6.MEC26 { @media #{$large-up} { font-size: 18px; }; }
 }
cite.MEC26{
color: $COL9;
}
/* Primary:3 */
.MES3 {
background-color: $COL3;
color: $COL2;
@media #{$medium-up} {
padding: 0;

}
 }
.MES3 {
background-color: $COL3;
color: $COL2;
@media #{$medium-up} {
padding: 0;

}
h1.MEC3, h2.MEC3, h3.MEC3, h4.MEC3, h5.MEC3, h6.MEC3 { color: $COL2;
 }
h1.MEC3 { font-size: 22.5px; }
h1.MEC3 { @media #{$large-up} { font-size: 40px; }; }
h2.MEC3 { font-size: 19.8px; }
h2.MEC3 { @media #{$large-up} { font-size: 35px; }; }
h3.MEC3 { font-size: 18px; }
h3.MEC3 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC3 { font-size: 16.2px; }
h4.MEC3 { @media #{$large-up} { font-size: 25px; }; }
h5.MEC3 { font-size: 16.2px; }
h5.MEC3 { @media #{$large-up} { font-size: 20px; }; }
h6.MEC3 { font-size: 14.4px; }
h6.MEC3 { @media #{$large-up} { font-size: 18px; }; }
 }
cite.MEC3{
color: $COL2;
}
/* Secondary:13 */
.MES13 {
background-color: $COL4;
color: $COL2;
&:hover { color: $COL2;}
padding: 10px 30px;

 }
/* Secondary:4 */
.MES4 {
background-color: $COL4;
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://longrangeautomotive.com.au/img/350/66');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: contain;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: $COL2;
padding: 10px 0;

@media #{$medium-up} {
padding: 0;

}
 }
.MES4 {
background-color: $COL4;
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://longrangeautomotive.com.au/img/350/66');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: contain;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: $COL2;
padding: 10px 0;

@media #{$medium-up} {
padding: 0;

}
h1.MEC4, h2.MEC4, h3.MEC4, h4.MEC4, h5.MEC4, h6.MEC4 { color: $COL2;
 }
h1.MEC4 { font-size: 22.5px; }
h1.MEC4 { @media #{$large-up} { font-size: 40px; }; }
h2.MEC4 { font-size: 19.8px; }
h2.MEC4 { @media #{$large-up} { font-size: 35px; }; }
h3.MEC4 { font-size: 18px; }
h3.MEC4 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC4 { font-size: 16.2px; }
h4.MEC4 { @media #{$large-up} { font-size: 25px; }; }
h5.MEC4 { font-size: 16.2px; }
h5.MEC4 { @media #{$large-up} { font-size: 20px; }; }
h6.MEC4 { font-size: 14.4px; }
h6.MEC4 { @media #{$large-up} { font-size: 18px; }; }
 }
cite.MEC4{
color: $COL2;
}
/* Tertiary:5 */
.MES5 {
background-color: $COL5;
color: $COL2;
font-size: 16px;
@media #{$large-up} {
font-size: 20px;
};
@media #{$medium-up} {
padding: 0;

}
 }
.MES5 {
background-color: $COL5;
color: $COL2;
font-size: 16px;
@media #{$large-up} {
font-size: 20px;
};
@media #{$medium-up} {
padding: 0;

}
h1.MEC5, h2.MEC5, h3.MEC5, h4.MEC5, h5.MEC5, h6.MEC5 { color: $COL2;
 }
h1.MEC5 { font-size: 22.5px; }
h1.MEC5 { @media #{$large-up} { font-size: 40px; }; }
h2.MEC5 { font-size: 19.8px; }
h2.MEC5 { @media #{$large-up} { font-size: 35px; }; }
h3.MEC5 { font-size: 18px; }
h3.MEC5 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC5 { font-size: 16.2px; }
h4.MEC5 { @media #{$large-up} { font-size: 25px; }; }
h5.MEC5 { font-size: 16.2px; }
h5.MEC5 { @media #{$large-up} { font-size: 20px; }; }
h6.MEC5 { font-size: 14.4px; }
h6.MEC5 { @media #{$large-up} { font-size: 18px; }; }
 }
cite.MEC5{
color: $COL2;
font-size: 16px;
@media #{$large-up} {
font-size: 20px;
};
}
/* Shade 1:6 */
.MES6 {
background-color: $COL6;
color: $COL3;
@media #{$medium-up} {
padding: 0;

}
 }
.MES6 {
background-color: $COL6;
color: $COL3;
@media #{$medium-up} {
padding: 0;

}
h1.MEC6, h2.MEC6, h3.MEC6, h4.MEC6, h5.MEC6, h6.MEC6 { color: $COL3;
 }
h1.MEC6 { font-size: 10px; }
h1.MEC6 { @media #{$medium-up} { font-size: 21px; }; }
h1.MEC6 { @media #{$large-up} { font-size: 40px; }; }
h2.MEC6 { font-size: 8.8px; }
h2.MEC6 { @media #{$medium-up} { font-size: 18px; }; }
h2.MEC6 { @media #{$large-up} { font-size: 35px; }; }
h3.MEC6 { font-size: 8px; }
h3.MEC6 { @media #{$medium-up} { font-size: 16.8px; }; }
h3.MEC6 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC6 { font-size: 7.2px; }
h4.MEC6 { @media #{$medium-up} { font-size: 15px; }; }
h4.MEC6 { @media #{$large-up} { font-size: 25px; }; }
h5.MEC6 { font-size: 7.2px; }
h5.MEC6 { @media #{$medium-up} { font-size: 12px; }; }
h5.MEC6 { @media #{$large-up} { font-size: 20px; }; }
h6.MEC6 { font-size: 6.4px; }
h6.MEC6 { @media #{$medium-up} { font-size: 10.8px; }; }
h6.MEC6 { @media #{$large-up} { font-size: 18px; }; }
 }
cite.MEC6{
color: $COL3;
}
/* Shade 2:7 */
.MES7 {
background-color: $COL7;
color: $COL9;
padding: 20px 0;

 }
.MES7 {
background-color: $COL7;
color: $COL9;
padding: 20px 0;

h1.MEC7, h2.MEC7, h3.MEC7, h4.MEC7, h5.MEC7, h6.MEC7 { color: $COL3;
 }
h1.MEC7 { font-size: 22.5px; }
h1.MEC7 { @media #{$large-up} { font-size: 40px; }; }
h2.MEC7 { font-size: 19.8px; }
h2.MEC7 { @media #{$large-up} { font-size: 35px; }; }
h3.MEC7 { font-size: 18px; }
h3.MEC7 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC7 { font-size: 16.2px; }
h4.MEC7 { @media #{$large-up} { font-size: 25px; }; }
h5.MEC7 { font-size: 16.2px; }
h5.MEC7 { @media #{$large-up} { font-size: 20px; }; }
h6.MEC7 { font-size: 14.4px; }
h6.MEC7 { @media #{$large-up} { font-size: 18px; }; }
 }
cite.MEC7{
color: $COL9;
}
/* Primary:8 */
.MES8 {
background-color: $COL3;
color: $COL2;
&:hover { color: $COL2;}
padding: 10px 30px;

 }
/* Home Buttons:9 */
.MES9 {
background-color: $COL8;
color: $COL2;
&:hover { color: $COL2;}
padding: 15px 20px;

@media #{$medium-up} {
padding: 20px;

}
@media #{$large-up} {
padding: 20px 30px;

}
 }
/* Tertiary:10 */
.MES10 {
background-color: $COL5;
color: $COL2;
&:hover { color: $COL2;}
padding: 10px 20px;

@media #{$medium-up} {
padding: 10px 30px;

}
 }
/* Secondary:11 */
.MES11 {
background-color: $COL4;
color: $COL2;
padding: 20px;

 }
.MES11 {
background-color: $COL4;
color: $COL2;
padding: 20px;

h1.MEC11, h2.MEC11, h3.MEC11, h4.MEC11, h5.MEC11, h6.MEC11 { color: $COL2;
 }
h1.MEC11 { font-size: 22.5px; }
h1.MEC11 { @media #{$large-up} { font-size: 40px; }; }
h2.MEC11 { font-size: 19.8px; }
h2.MEC11 { @media #{$large-up} { font-size: 35px; }; }
h3.MEC11 { font-size: 18px; }
h3.MEC11 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC11 { font-size: 16.2px; }
h4.MEC11 { @media #{$large-up} { font-size: 25px; }; }
h5.MEC11 { font-size: 16.2px; }
h5.MEC11 { @media #{$large-up} { font-size: 20px; }; }
h6.MEC11 { font-size: 14.4px; }
h6.MEC11 { @media #{$large-up} { font-size: 18px; }; }
 }
cite.MEC11{
color: $COL2;
}
/* Bigger button:12 */
.MES12 {
background-color: $COL8;
color: $COL2;
&:hover { color: $COL2;}
padding: 15px 20px;

@media #{$medium-up} {
padding: 30px;

}
@media #{$large-up} {
padding: 30px 40px;

}
 }
/* side button:14 */
.MES14 {
background-color: $COL3;
color: $COL2;
&:hover { color: $COL2;}
padding: 30px 20px;

@media #{$medium-up} {
padding: 30px;

}
 }
/* Link-image box:25 */
.MES25 {
background-color: $COL10;
color: $COL2;
padding: 10px;

@media #{$medium-up} {
padding: 20px 10px;

}
 }
.MES25 {
background-color: $COL10;
color: $COL2;
padding: 10px;

@media #{$medium-up} {
padding: 20px 10px;

}
h1.MEC25, h2.MEC25, h3.MEC25, h4.MEC25, h5.MEC25, h6.MEC25 { color: $COL2;
 }
h1.MEC25 { font-size: 10px; }
h1.MEC25 { @media #{$medium-up} { font-size: 21px; }; }
h1.MEC25 { @media #{$large-up} { font-size: 40px; }; }
h2.MEC25 { font-size: 8.8px; }
h2.MEC25 { @media #{$medium-up} { font-size: 18px; }; }
h2.MEC25 { @media #{$large-up} { font-size: 35px; }; }
h3.MEC25 { font-size: 8px; }
h3.MEC25 { @media #{$medium-up} { font-size: 16.8px; }; }
h3.MEC25 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC25 { font-size: 7.2px; }
h4.MEC25 { @media #{$medium-up} { font-size: 15px; }; }
h4.MEC25 { @media #{$large-up} { font-size: 25px; }; }
h5.MEC25 { font-size: 7.2px; }
h5.MEC25 { @media #{$medium-up} { font-size: 12px; }; }
h5.MEC25 { @media #{$large-up} { font-size: 20px; }; }
h6.MEC25 { font-size: 6.4px; }
h6.MEC25 { @media #{$medium-up} { font-size: 10.8px; }; }
h6.MEC25 { @media #{$large-up} { font-size: 18px; }; }
 }
cite.MEC25{
color: $COL2;
}
/* Side Button:15 */
.MES15 {
background-color: $COL4;
color: $COL2;
&:hover { color: $COL2;}
padding: 30px 20px;

@media #{$medium-up} {
padding: 30px;

}
 }
/* Side button:16 */
.MES16 {
background-color: $COL5;
color: $COL2;
&:hover { color: $COL2;}
padding: 30px 20px;

@media #{$medium-up} {
padding: 30px;

}
 }
/* banner heading:17 */
.MES17 {
background-color: $COL8;
color: $COL2;
font-size: 16px;
@media #{$medium-up} {
font-size: 24px;
};
@media #{$large-up} {
font-size: 40px;
};
padding: 10px;

@media #{$medium-up} {
padding: 10px 10px 20px 10px;

}
@media #{$large-up} {
padding: 10px 60px 20px 60px;

}
 }
.MES17 {
background-color: $COL8;
color: $COL2;
font-size: 16px;
@media #{$medium-up} {
font-size: 24px;
};
@media #{$large-up} {
font-size: 40px;
};
padding: 10px;

@media #{$medium-up} {
padding: 10px 10px 20px 10px;

}
@media #{$large-up} {
padding: 10px 60px 20px 60px;

}
h1.MEC17, h2.MEC17, h3.MEC17, h4.MEC17, h5.MEC17, h6.MEC17 { color: $COL2;
 }
h1.MEC17 { font-size: 20px; }
h1.MEC17 { @media #{$medium-up} { font-size: 31.5px; }; }
h1.MEC17 { @media #{$large-up} { font-size: 48px; }; }
h2.MEC17 { font-size: 17.6px; }
h2.MEC17 { @media #{$medium-up} { font-size: 27px; }; }
h2.MEC17 { @media #{$large-up} { font-size: 42px; }; }
h3.MEC17 { font-size: 16px; }
h3.MEC17 { @media #{$medium-up} { font-size: 25.2px; }; }
h3.MEC17 { @media #{$large-up} { font-size: 36px; }; }
h4.MEC17 { font-size: 14.4px; }
h4.MEC17 { @media #{$medium-up} { font-size: 22.5px; }; }
h4.MEC17 { @media #{$large-up} { font-size: 30px; }; }
h5.MEC17 { font-size: 14.4px; }
h5.MEC17 { @media #{$medium-up} { font-size: 18px; }; }
h5.MEC17 { @media #{$large-up} { font-size: 24px; }; }
h6.MEC17 { font-size: 12.8px; }
h6.MEC17 { @media #{$medium-up} { font-size: 16.2px; }; }
h6.MEC17 { @media #{$large-up} { font-size: 21.6px; }; }
 }
cite.MEC17{
color: $COL2;
font-size: 16px;
@media #{$medium-up} {
font-size: 24px;
};
@media #{$large-up} {
font-size: 40px;
};
}
/* padding:18 */
.MES18 {
background-color: $COL10;
color: $COL2;
padding: 30px 10px;

@media #{$medium-up} {
padding: 50px 10px;

}
 }
.MES18 {
background-color: $COL10;
color: $COL2;
padding: 30px 10px;

@media #{$medium-up} {
padding: 50px 10px;

}
h1.MEC18, h2.MEC18, h3.MEC18, h4.MEC18, h5.MEC18, h6.MEC18 { color: $COL2;
 }
h1.MEC18 { font-size: 10px; }
h1.MEC18 { @media #{$medium-up} { font-size: 21px; }; }
h1.MEC18 { @media #{$large-up} { font-size: 40px; }; }
h2.MEC18 { font-size: 8.8px; }
h2.MEC18 { @media #{$medium-up} { font-size: 18px; }; }
h2.MEC18 { @media #{$large-up} { font-size: 35px; }; }
h3.MEC18 { font-size: 8px; }
h3.MEC18 { @media #{$medium-up} { font-size: 16.8px; }; }
h3.MEC18 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC18 { font-size: 7.2px; }
h4.MEC18 { @media #{$medium-up} { font-size: 15px; }; }
h4.MEC18 { @media #{$large-up} { font-size: 25px; }; }
h5.MEC18 { font-size: 7.2px; }
h5.MEC18 { @media #{$medium-up} { font-size: 12px; }; }
h5.MEC18 { @media #{$large-up} { font-size: 20px; }; }
h6.MEC18 { font-size: 6.4px; }
h6.MEC18 { @media #{$medium-up} { font-size: 10.8px; }; }
h6.MEC18 { @media #{$large-up} { font-size: 18px; }; }
 }
cite.MEC18{
color: $COL2;
}
/* secondary no padding:19 */
.MES19 {
background-color: $COL4;
color: $COL2;
@media #{$medium-up} {
padding: 0;

}
 }
.MES19 {
background-color: $COL4;
color: $COL2;
@media #{$medium-up} {
padding: 0;

}
h1.MEC19, h2.MEC19, h3.MEC19, h4.MEC19, h5.MEC19, h6.MEC19 { color: $COL2;
 }
h1.MEC19 { font-size: 22.5px; }
h1.MEC19 { @media #{$large-up} { font-size: 40px; }; }
h2.MEC19 { font-size: 19.8px; }
h2.MEC19 { @media #{$large-up} { font-size: 35px; }; }
h3.MEC19 { font-size: 18px; }
h3.MEC19 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC19 { font-size: 16.2px; }
h4.MEC19 { @media #{$large-up} { font-size: 25px; }; }
h5.MEC19 { font-size: 16.2px; }
h5.MEC19 { @media #{$large-up} { font-size: 20px; }; }
h6.MEC19 { font-size: 14.4px; }
h6.MEC19 { @media #{$large-up} { font-size: 18px; }; }
 }
cite.MEC19{
color: $COL2;
}
/* Price Block:20 */
.MES20 {
background-color: $COL7;
color: $COL9;
padding: 20px 0;

 }
.MES20 {
background-color: $COL7;
color: $COL9;
padding: 20px 0;

h1.MEC20, h2.MEC20, h3.MEC20, h4.MEC20, h5.MEC20, h6.MEC20 { color: $COL3;
 }
h1.MEC20 { font-size: 22.5px; }
h1.MEC20 { @media #{$large-up} { font-size: 40px; }; }
h2.MEC20 { font-size: 19.8px; }
h2.MEC20 { @media #{$large-up} { font-size: 35px; }; }
h3.MEC20 { font-size: 18px; }
h3.MEC20 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC20 { font-size: 16.2px; }
h4.MEC20 { @media #{$large-up} { font-size: 25px; }; }
h5.MEC20 { font-size: 16.2px; }
h5.MEC20 { @media #{$large-up} { font-size: 20px; }; }
h6.MEC20 { font-size: 14.4px; }
h6.MEC20 { @media #{$large-up} { font-size: 18px; }; }
 }
cite.MEC20{
color: $COL9;
}
/* Radius:21 */
.MES21 {
background-color: $COL4;
color: $COL2;
border-radius: 5px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 15px 20px;

border-width: 1px;
border-style: solid;
border-color: $COL14;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES21 {
background-color: $COL4;
color: $COL2;
border-radius: 5px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 15px 20px;

border-width: 1px;
border-style: solid;
border-color: $COL14;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
h1.MEC21, h2.MEC21, h3.MEC21, h4.MEC21, h5.MEC21, h6.MEC21 { color: $COL2;
 }
h1.MEC21 { font-size: 22.5px; }
h1.MEC21 { @media #{$large-up} { font-size: 40px; }; }
h2.MEC21 { font-size: 19.8px; }
h2.MEC21 { @media #{$large-up} { font-size: 35px; }; }
h3.MEC21 { font-size: 18px; }
h3.MEC21 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC21 { font-size: 16.2px; }
h4.MEC21 { @media #{$large-up} { font-size: 25px; }; }
h5.MEC21 { font-size: 16.2px; }
h5.MEC21 { @media #{$large-up} { font-size: 20px; }; }
h6.MEC21 { font-size: 14.4px; }
h6.MEC21 { @media #{$large-up} { font-size: 18px; }; }
 }
cite.MEC21{
color: $COL2;
}
/* Quote Button:22 */
.MES22 {
background-color: $COL8;
color: $COL2;
&:hover { color: $COL2;}
padding: 20px;

@media #{$medium-up} {
padding: 40px 20px;

}
@media #{$large-up} {
padding: 40px 30px;

}
 }
/* top bar:23 */
.MES23 {
background-color: $COL10;
color: $COL3;
@media #{$medium-up} {
padding: 0;

}
 }
.MES23 {
background-color: $COL10;
color: $COL3;
@media #{$medium-up} {
padding: 0;

}
h1.MEC23, h2.MEC23, h3.MEC23, h4.MEC23, h5.MEC23, h6.MEC23 { color: $COL3;
 }
h1.MEC23 { font-size: 10px; }
h1.MEC23 { @media #{$medium-up} { font-size: 21px; }; }
h1.MEC23 { @media #{$large-up} { font-size: 40px; }; }
h2.MEC23 { font-size: 8.8px; }
h2.MEC23 { @media #{$medium-up} { font-size: 18px; }; }
h2.MEC23 { @media #{$large-up} { font-size: 35px; }; }
h3.MEC23 { font-size: 8px; }
h3.MEC23 { @media #{$medium-up} { font-size: 16.8px; }; }
h3.MEC23 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC23 { font-size: 7.2px; }
h4.MEC23 { @media #{$medium-up} { font-size: 15px; }; }
h4.MEC23 { @media #{$large-up} { font-size: 25px; }; }
h5.MEC23 { font-size: 7.2px; }
h5.MEC23 { @media #{$medium-up} { font-size: 12px; }; }
h5.MEC23 { @media #{$large-up} { font-size: 20px; }; }
h6.MEC23 { font-size: 6.4px; }
h6.MEC23 { @media #{$medium-up} { font-size: 10.8px; }; }
h6.MEC23 { @media #{$large-up} { font-size: 18px; }; }
 }
cite.MEC23{
color: $COL3;
}
/* Footer Block:24 */
.MES24 {
background-color: $COL3;
color: $COL2;
@media #{$medium-up} {
padding: 0;

}
 }
.MES24 {
background-color: $COL3;
color: $COL2;
@media #{$medium-up} {
padding: 0;

}
h1.MEC24, h2.MEC24, h3.MEC24, h4.MEC24, h5.MEC24, h6.MEC24 { color: $COL2;
 }
h1.MEC24 { font-size: 22.5px; }
h1.MEC24 { @media #{$large-up} { font-size: 40px; }; }
h2.MEC24 { font-size: 19.8px; }
h2.MEC24 { @media #{$large-up} { font-size: 35px; }; }
h3.MEC24 { font-size: 18px; }
h3.MEC24 { @media #{$large-up} { font-size: 30px; }; }
h4.MEC24 { font-size: 16.2px; }
h4.MEC24 { @media #{$large-up} { font-size: 25px; }; }
h5.MEC24 { font-size: 16.2px; }
h5.MEC24 { @media #{$large-up} { font-size: 20px; }; }
h6.MEC24 { font-size: 14.4px; }
h6.MEC24 { @media #{$large-up} { font-size: 18px; }; }
 }
cite.MEC24{
color: $COL2;
}
/* Important:50 */
.MES50 {
color: $COL39;
 }
.MES50 {
color: $COL39;
h1.MEC50, h2.MEC50, h3.MEC50, h4.MEC50, h5.MEC50, h6.MEC50 { color: $COL39;
 }
 }
cite.MEC50{
color: $COL39;
}

$COL1: #000000; //black
$COLFLAT1: #000000; //black
$COL2: #ffffff; //white
$COLFLAT2: #ffffff; //white
$COL3: #3f71b7; //Primary
$COLFLAT3: #3f71b7; //Primary
$COL4: #233753; //Secondary
$COLFLAT4: #233753; //Secondary
$COL5: #fa8621; //Tertiary
$COLFLAT5: #fa8621; //Tertiary
$COL6: #c2c2c2; //Shade 1
$COLFLAT6: #c2c2c2; //Shade 1
$COL7: #f2f2f2; //Shade 2
$COLFLAT7: #f2f2f2; //Shade 2
$COL8: rgba(0,0,0,0.439); //transparent black
$COLFLAT8: #484848; //transparent black
$COL9: #555555; //font
$COLFLAT9: #555555; //font
$COL10: rgba(0,0,0,0); //transparent
$COLFLAT10: #808080; //transparent
$COL11: #dddddd; //grey border
$COLFLAT11: #dddddd; //grey border
$COL12: rgba(0,0,0,0); //transparent
$COLFLAT12: #808080; //transparent
$COL13: rgba(0,0,0,0); //
$COLFLAT13: #808080; //
$COL14: #ffffff; //
$COLFLAT14: #ffffff; //
$COL15: #555555; //
$COLFLAT15: #555555; //
$COL16: #3f71b7; //
$COLFLAT16: #3f71b7; //
$COL17: #3f71b7; //
$COLFLAT17: #3f71b7; //
$COL18: #3f71b7; //
$COLFLAT18: #3f71b7; //
$COL19: #3f71b7; //
$COLFLAT19: #3f71b7; //
$COL20: #3f71b7; //
$COLFLAT20: #3f71b7; //
$COL21: #3f71b7; //
$COLFLAT21: #3f71b7; //
$COL22: #555555; //
$COLFLAT22: #555555; //
$COL23: #3f71b7; //
$COLFLAT23: #3f71b7; //
$COL24: #333333; //
$COLFLAT24: #333333; //
$COL25: #3f71b7; //
$COLFLAT25: #3f71b7; //
$COL26: #ffffff; //
$COLFLAT26: #ffffff; //
$COL27: #233753; //
$COLFLAT27: #233753; //
$COL28: #ffffff; //
$COLFLAT28: #ffffff; //
$COL29: #fa8621; //
$COLFLAT29: #fa8621; //
$COL30: #ffffff; //
$COLFLAT30: #ffffff; //
$COL31: #f2f2f2; //
$COLFLAT31: #f2f2f2; //
$COL32: #555555; //
$COLFLAT32: #555555; //
$COL33: #555555; //
$COLFLAT33: #555555; //
$COL34: #f8f8f8; //Light
$COLFLAT34: #f8f8f8; //Light
$COL35: rgba(35,55,83,0.902); //Dark Blue 90%
$COLFLAT35: #2c3e57; //Dark Blue 90%
$COL36: rgba(120,120,120,0.8); //Grey 80%
$COLFLAT36: #7a7a7a; //Grey 80%
$COL37: rgba(63,113,183,0.102); //Light Blue 10%
$COLFLAT37: #797e86; //Light Blue 10%
$COL38: rgba(255,255,255,0.2); //White 20%
$COLFLAT38: #999999; //White 20%
$COL39: #cf0000; //Important red
$COLFLAT39: #cf0000; //Important red
/* WARNING: Unable to find output for 1:base style (type=base)*/
$breakpoints: (
  small: 0,
  medium: 641px,
  large: 1024px,
  xlarge: 1248px,
  xxlarge: 1936px,
);
$global-width: 1080px;

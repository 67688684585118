.testim-list {
  .quote-author, .quote-body{
    font-size: inherit !important;
  }
}

.testimonials {
  .column.item:nth-child(odd) {
    background-color: #F2F2F2;
  }
}

.testimonials.me-FilterContent{
  .me-block.me-Hamle{
    position: relative;
    .testim-summary{
      p{
        font-family: Arapey;
        font-size: 20px;
      }
    }
    .testim-addr{
      margin-top: 20px;
      .author, .company{
        font-family: OpenSansBold, sans-serif;
      }
    }
    .ql,.qr{
      color:#333333;
      font-size: 50px;
      opacity: 0.1;
      position: absolute;
      @media screen and (max-width: 640px) {
        font-size: 30px;
      }
    }
    .ql{
      left:0;
      top:0;
    }
    .qr{
      bottom:0;
      right:0;
    }
  }
  ul{
    margin:0;
    li.item{
      padding: 50px;
      @media screen and (max-width: 640px) {
        padding: 30px;
      }
      &:nth-child(odd){
        background:#f2f2f2;
      }
      &:nth-child(even){
        background: #ffffff;
      }
    }
  }
}

.me-owrap.me-doc {
  .site-header {
    .me-Menu.me-block {
      & > ul {
        text-align: right;
        & > li {
          display: inline-block;
          a {
            background-color: rgba(0, 0, 0, 0);
            border: 0;
            color: #3f71b7;
            text-transform: uppercase;
          }
        }
      }
    }
  }
  //submenu panel(below banner)
  .submenu-panel {
    background-color : #F2F2F2;
    ul{
      margin: 0;
      li{
        display:inline-block;
        margin: 0 5px;
        a{
          display:block;
          padding: 10px;
          color: #555555 !important;
        }
        &.active{
          background-color:#DDDDDD;
        }
      }
    }
    @media screen and (max-width: 640px){
      display:none;
    }
  }

  .me-owrap.me-Carousel {
    .slider-arrow{
      background-color: #B2C6E2;
      height: 60px;
      text-align: center;
      width: 60px;
      @media screen and (max-width: 1024px){
        display:none;
      }
      i{
        width: 0;
        height: 0;
      }
      i:before{content:"";}
      @media screen and (max-width: 640px){
        display:none;
      }
    }
    .slider-arrow.slider-arrow-left{
      left: -60px;
      i{
        border-bottom: 15px solid rgba(0, 0, 0, 0);
        border-right: 20px solid #FFF;
        border-top: 15px solid rgba(0, 0, 0, 0);
      }
    }
    .slider-arrow.slider-arrow-right{
      right: -60px;
      i{
        border-bottom: 15px solid rgba(0, 0, 0, 0);
        border-left: 20px solid #FFF;
        border-top: 15px solid rgba(0, 0, 0, 0);
      }
    }
  }
  .home-news-block, .news-block{
    background-color: #f2f2f2;
    figure {
      margin:0;
      img{
        width: 100%;
      }
    }
    .me-owrap.me-Field {
      //background-color: #CED8E6;
      padding: 0 20px;
      h5{
        margin-top: 0;
        padding: 10px 0;
      }
    }
    .me-owrap.me-CKEditor {
      font-size: 14px;
      padding: 10px 20px;
      p{text-align: justify;}
    }
  }
  .home-news-block{
    figure {
      height: 190px;
      margin: 0;
      overflow: hidden;
      padding: 0;
      position: relative;
      img{
        height: 190px;
        left: 50%;
        max-width: 200%;
        position: absolute;
        transform: translateX(-50%);
        width: auto;
      }
    }
  }
  // Testimonals styles
  .site-testim{
    .me-block.me-Hamle{
      position: relative;
    }
    .ql,.qr{
      color:white;
      font-size: 100px;
      opacity: 0.4;
      position: absolute;
      @media screen and (max-width: 640px) {
        font-size: 50px;
      }
    }
    .ql{
      left:0;
      top:0;
    }
    .qr{
      bottom:0;
      right:0;
    }
    .testim-summary{
      color: #FFF !important;
      font-family: Arapey;
      font-size: 35px;
      line-height: 1.5;
      padding: 40px 0 60px;
      text-align: center;
      @media screen and (max-width: 640px) {
        font-size: 20px;
      }
    }
    .testim-addr{
      margin-top: 20px;
      text-align: center;
      .author, .company{
        color: white;
        font-family: OpenSansBold, sans-serif;
        padding: 0;
      }
      .position{color:white;}
    }
  }
  .testim-heading h2{
    margin: 0;
    line-height: 1;
  }



  .home-button{
    .button{
      max-width: 300px;
      width: 100%;
    }
  }
  //.me-block.me-Form{
  //  input[type='submit']{
  //    background-color: #3f71b7;
  //    border-radius: 5px;
  //    padding: 10px 30px;
  //  }
  //}

  //.contact-form{
  //  .me-block.me-Form{
  //    @include placeholder(black);
  //    input:not([type="submit"]), textarea{
  //      background-color: rgba(63, 113, 183, 0.1);
  //      border:1px solid #3f71b7;
  //      border-radius: 3px;
  //    }
  //    textarea{min-height: 150px;}
  //    input[type="submit"] {
  //      width:100%;
  //      max-width: 300px;
  //    }
  //  }
  //}

  //.me-FilterContent.site-news{
  //  .list{
  //    margin:0;
  //    .me-block.me-Hamle{display:table;}
  //    i,a{
  //      display:table-cell;
  //      vertical-align: middle;
  //    }
  //    i{
  //      color:white;
  //    }
  //    a{
  //      font-size: 14px;
  //      line-height: 1.5;
  //    }
  //  }
  //}

  //.me-block.me-Gallery{
  //  .heroimg{
  //    position: relative;
  //    img{padding: 0 !important;}
  //    .caption {
  //      background-color: rgba(0, 0, 0, 0.3);
  //      bottom: 0;
  //      color: white;
  //      display: block;
  //      padding: 10px;
  //      position: absolute;
  //      width: 100%;
  //    }
  //  }
  //}

  //.site-footer {
  //  padding-bottom: 0 !important;
  //  .me-block.me-Panel2{
  //    .me-block.me-CKEditor {
  //      ul{
  //        margin: 0;
  //        list-style: none;
  //        li{
  //          color:white;
  //          display:inline-block;
  //          vertical-align: middle;
  //          width: 49%;
  //          @media screen and (max-width: 480px){
  //            display: block;
  //            margin: 10px auto;
  //            text-align: center;
  //            width: 100%;
  //          }
  //        }
  //      }
  //      font-size: 14px;
  //      a{
  //        color: white;
  //      }
  //      .phone{
  //        font-size: 18px;
  //      }
  //    }
  //    @media screen and (max-width: 640px) {
  //      text-align: center;
  //      [data-align="left"] {text-align: center !important;}
  //    }
  //    //.me-Image.me-block{
  //    //  img{
  //    //    //@media screen and (max-width: 980px) {
  //    //    //  width: 30%;
  //    //    //}
  //    //  }
  //    //}
  //  }
  //  .me-block.me-FilterContent{
  //    a{
  //      color:white;
  //      font-size: 40px;
  //    }
  //  }
  //}

//  Manu model list
  .hide-able > .row {
    padding: 20px 0;
    & > .columns:nth-child(2) > div{width: 100%;}
    figure{margin:1px auto; max-width: 143px !important;}
    @media screen and (max-width:640px){
      figure{margin-bottom: 10px;}
      p{text-align: center;}
    }
    &:nth-child(odd){
      background-color:#F2F2F2;
    }
    &:nth-child(even){
      background-color:#FFFFFF;
    }
  }
}